export class FirstInput {

  gd = '';
  stg = '';
  mLohn: number = null;
  aghStich: number = null;
  anw: number = null;
  anwG: number = null;
  pdate = '';
  sparplan = '';
  zins: number = null;
}

export class Einkauf {

  einkaufsreihe: Array<number> = [0, 1];
  einkaufssession: Array<number> = [0, 0];
  einkaufsdatum: Array<any> = [null, null];
  einkaufsdatumbe: Array<any> = [null, null];
  einkaufshoehe: Array<number> = [null, null];
  showEinkaeufegetaetigt = false;
  einkauferror = false;
  einkaufmodalanzeigen = false;
  warningtexteinkauf = false;
  maxeinkauf: Array<number> = [null, null];
  einkaufzwischen: Array<number> = [0, 0];

}

export class Kapital {
  maxkapitalbezugbetrag = 0;
  maxkapitalbezugprozent = 0;
  kapitalbezugprozentual = 0;
  hoehekapitalbezugproz = 0;
  showKapitalbezug = false;
  hoehekapitalbezug: number = null;
  hoehekapitalbezugspeicher: number = null;
  zwischenstrafe = 0;
  einmaleinlagenichtbezahlt = 0;

}

export class Lohn {
  lohnreihe = [0];
  lohnsession = [0, 0];
  datumlohnaenderung = [null];
  datumlohnaenderungbe = [null];
  neuermassgebenderlohn = [null];
  hoehelohnaenderung: Array<number> = [null];
  lohnspeicher = [null];
  showLohnaenderungen = false;
  lohnerror = false;
  warnungzweiterlohnn = false;
  hoehelohnaenderungausgabe = [0, 0, 0, 0, 0, 0, 0, 0, 0];
}

export class Design {

  showMyContainer = false;

}
