import {Component} from '@angular/core';
import {Einkauf, FirstInput, Kapital, Lohn, Design} from './services/input.model';
import {AghService} from './services/agh.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sgpk-rechner';
  // Toogle:
  // Rentenrelevantes AGH:
  pensagh: number;
  uws: any;
  rente: number;
  monrente: number;
  // Variable Session
  session = 0;
  einkauf = new Einkauf();
  kap = new Kapital();
  lohn = new Lohn();
  design = new Design();
// Inuptvalidation
  gderror = false;
  stgerror = false;
  aghSticherror = false;
  mlohnerror = false;
  anwerror = false;
  anwGerror = false;
  fruehestes;
  spaetestes;
  pdateerror = false;
  hilfpensaghkapspeiercher;
  altermonate;
  alter;
  fi = new FirstInput();
  maxKapitalbezugProzent = 1; // wieviel % vom agh kann bei einer pensionierung als kapital bezogen werden? --> Reglementarisch

  // Submit:
  constructor() {
    // Radio Button checked
    this.fi.sparplan = 'Standard';
    this.fi.zins = 0.01;
   /* Raus nehmen für Live version
    this.fi.aghStich = 431448;
    this.fi.mLohn = 160323;
    this.fi.anw = 81974;
    this.fi.anwG = 0;
    this.fi.pdate = '30.06.24';
    this.fi.stg = '1.1.19';
    this.fi.gd = '20.6.1959';*/
  }

  ersteInputkontrolle() {
    // Kontrolliert die Eingaben auf der Startseite, falls in Orndung geht die Berechnung los
    const errorarray = [false, false, false, false, false, false, false];
    if (this.fi.gd === '' || !this.checkdate(this.fi.gd)) {
      errorarray[0] = true;
    }
    if (this.fi.stg === '' || !this.checkdate(this.fi.stg)) {
      errorarray[1] = true;
    }
    if (errorarray[1] === false) {
      this.calcpensscope();
    }
    if (isNaN(this.fi.mLohn) || this.fi.mLohn < 14340) {
      errorarray[2] = true;
    }
    if (isNaN(this.fi.aghStich) || this.fi.aghStich < 0 || this.fi.aghStich === null) {
      errorarray[3] = true;
    }
    if (isNaN(this.fi.anw) && this.fi.anw != null) {
      errorarray[4] = true;
    }
    if (isNaN(this.fi.anwG) && this.fi.anwG != null) {
      errorarray[5] = true;
    }
    if (this.fi.pdate === '' || !this.checkdate(this.fi.pdate) || !this.calcpensscope()) {
      errorarray[6] = true;
    }
    // Zeigt die Fehler an, falls welche vorhanden und bricht ab
    this.setErrors(errorarray);
    for (let i = 0; i < 7; i++) {
      if (errorarray[i] === true) {
        return;
      }
    }

    // Falls alles in Ordnung ist
    this.erstesAGH();
    this.design.showMyContainer = true;
    this.setErrors([false, false, false, false, false, false, false]);
  }

  setErrors(errorarray) {
    // Zeigt die Fehler an, falls welche vorhanden
    this.gderror = errorarray[0];
    this.stgerror = errorarray[1];
    this.mlohnerror = errorarray[2];
    this.aghSticherror = errorarray[3];
    this.anwerror = errorarray[4];
    this.anwGerror = errorarray[5];
    this.pdateerror = errorarray[6];
  }

  checkdate(date: string) {
    // Kontrolliert ob die Daten im richtigen Eingabeformat sind.
    let isDate: RegExp;
    // tslint:disable-next-line:max-line-length
    isDate = /^(?:(?:31([\/\-.])(?:0?[13578]|1[02]))\1|(?:(?:29|30)([\/\-.])(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29([\/\-.])0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;


    return isDate.test(date);
  }

  calcpensscope() {
    // Range of 58 - 70 : Frühestes und spätestes Pensionierungsdatum
    const geb = AghService.sD(this.fi.gd);
    this.fruehestes = new Date(2019 - 1 - 1);
    this.fruehestes.setFullYear(geb.getFullYear() + 58);
    this.fruehestes.setDate(1);
    this.fruehestes.setMonth(geb.getMonth());
    // Falls früherstest for Stichtag --> Stichtag frühestes
    if (AghService.sD(this.fi.stg) > this.fruehestes) {
      this.fruehestes = AghService.sD(this.fi.stg);
    }
    this.spaetestes = new Date(2019 - 1 - 1);
    this.spaetestes.setFullYear(geb.getFullYear() + 70);
    this.spaetestes.setMonth(geb.getMonth() + 1);
    this.spaetestes.setDate(0);
    const pens = AghService.sD(this.fi.pdate);
    if (this.fruehestes < pens && pens < this.spaetestes) {
      // falls Pensdatum in Orndung, wird das Alter ausgewertet
      this.setPensAlter();
      return true;
    } else {
      return false;
    }
  }

  setPensAlter() {
    // Setzt Pensalter und Monat
    const geb = AghService.sD(this.fi.gd);
    const pens = AghService.sD(this.fi.pdate);
    this.alter = pens.getFullYear() - geb.getFullYear();
    if (geb.getMonth() <= pens.getMonth()) {
      this.altermonate = pens.getMonth() - geb.getMonth();

    } else {
      this.alter -= 1;
      this.altermonate = 11 - geb.getMonth() + pens.getMonth() + 1;

    }
  }

  erstesAGH() {
    const geb = AghService.sD(this.fi.gd);
    const st = AghService.sD(this.fi.stg);
    const end = AghService.sD(this.fi.pdate);
    const vl = AghService.calcVL(this.fi.mLohn);

    // tslint:disable-next-line:one-variable-per-declaration max-line-length
    this.pensagh = AghService.aghcalc(this.fi.aghStich, vl, end, st, this.fi.zins, geb, this.fi.sparplan);
    if (st >= new Date(2019)) {
      this.pensagh += this.einmaleinlage(end);
    }
    // Erster UWS
    this.uws = AghService.returnUWS(geb, end);
    this.uws = this.uws * 100;
    this.rentberechenen();
    this.lohn.lohnspeicher[0] = this.fi.mLohn;
  }

  addeinkauf() {
    this.einkauf.einkaufsreihe.push(this.einkauf.einkaufsreihe[this.einkauf.einkaufsreihe.length - 1] + 1);
  }

  deleteeinkauf(einkaufsanzeige) {

    for (let i = 0; i < this.einkauf.einkaufsreihe.length; i++) {
      if (this.einkauf.einkaufsreihe[i] === einkaufsanzeige) {
        this.einkauf.einkaufsreihe.splice(i, 1);
        this.einkauf.einkaufshoehe.splice(i, 1);
        this.einkauf.einkaufsdatum.splice(i, 1);
        this.pensagh -= this.einkauf.einkaufssession[i];
        this.einkauf.einkaufssession.splice(i, 1);
        this.einkauf.maxeinkauf.splice(i, 1);
        this.einkauf.einkaufzwischen.splice(i, 1);
        // einkaufsanzeige.splice(i, 1);

      }
      this.einkauf.einkaufsreihe[i] = i;
    }
  }

  einkaufInputkontrolle() {
    // Kontrolle Datum einkauf
    for (let i = 0; i < this.einkauf.einkaufsdatum.length; i++) {
      const date = this.checkdate(this.einkauf.einkaufsdatum[i]);
      const emptydate = this.einkauf.einkaufsdatum[i];
      let datetrue = false;
      if (date) {
        if (AghService.sD(this.einkauf.einkaufsdatum[i]) < AghService.sD(this.fi.stg) || AghService.sD(this.einkauf.einkaufsdatum[i]) > AghService.sD(this.fi.pdate)) {
          datetrue = true;
        }
      }
      const hohe = this.einkauf.einkaufshoehe[i];
      if ((!date || emptydate === null || hohe < 0) || datetrue) {
        return true;
      } else if (this.einkauf.einkaufsdatum[i + 1] === null && this.einkauf.einkaufshoehe[i + 1] === null) {
        i += 1;
      }
    }
  }

  einkaeufetaetigen() {
    // Kontrolloiert eingaben
    if (this.einkaufInputkontrolle()) {
      this.einkauf.einkauferror = this.einkaufInputkontrolle();
      return;
    } else if (!this.maxeinkauf()) {
      this.einkauf.einkauferror = true;
      return;
    } else {
      this.einkauf.einkauferror = false;
      document.getElementById('closeeinkauf').click();
    }

    for (let i = 0; i < this.einkauf.einkaufsdatum.length; i++) {
      if (this.einkauf.einkaufsdatum[i] != null || this.einkauf.einkaufshoehe[i] != null) {
        // Aktiviert Warnung im Lohn
        this.einkauf.warningtexteinkauf = true;
      }

    }
// Alle Eingaben der Reihe nach sortieren
    AghService.sortSzenarios(this.einkauf.einkaufsdatum, this.einkauf.einkaufshoehe);

// Einkaufseffekt berechnen:
    // tslint:disable-next-line:prefer-for-of
    const end = AghService.sD(this.fi.pdate);
    const geb = AghService.sD(this.fi.gd);

    for (let i = 0; i < this.einkauf.einkaufsdatum.length; i++) {
      // tslint:disable-next-line:max-line-length
      // Setzt neues Datum
      if (this.einkauf.einkaufshoehe[i] > 0 || (this.einkauf.einkaufshoehe[i] != null)) {
        this.einkauf.einkaufsdatumbe[i] = AghService.sD(this.einkauf.einkaufsdatum[i]);

        if ((this.einkauf.einkaufssession[i] > 0)) {
          this.pensagh -= this.einkauf.einkaufssession[i];
        }
        this.einkauf.einkaufssession[i] = AghService.aghcalc(this.einkauf.einkaufshoehe[i], 0, end, this.einkauf.einkaufsdatumbe[i], this.fi.zins, geb, this.fi.sparplan);
        this.pensagh += this.einkauf.einkaufssession[i];

      } else {
        i += 1;
      }
    }    // Rente Neu berechnen:
    this.rentberechenen();
    // Angaben Anzeigen
    this.einkauf.showEinkaeufegetaetigt = true;
    this.maxkapitalbezug();
    if (this.kap.showKapitalbezug) {
      this.kapitalbezug();
    }
  }

  lohnInputkontrolle() {
    // Kontrolle Datum einkauf
    for (let i = 0; i < this.lohn.datumlohnaenderung.length; i++) {
      const date = this.checkdate(this.lohn.datumlohnaenderung[i]);
      const emptydate = this.lohn.datumlohnaenderung[i];
      const hohe = this.lohn.neuermassgebenderlohn[i];
      let datetrue = false;
      if (date) {
        if (AghService.sD(this.lohn.datumlohnaenderung[i]) < AghService.sD(this.fi.stg) || AghService.sD(this.lohn.datumlohnaenderung[i]) > AghService.sD(this.fi.pdate)) {
          datetrue = true;
        }
      }
      if ((!date || emptydate === null || hohe === null || datetrue)) {
        return true;
      } else if (this.lohn.datumlohnaenderung[i + 1] === null && this.lohn.hoehelohnaenderung[i + 1] === null) {
        i += 1;
      }
    }
  }

  lohnaenderungensimulieren() {
    // Kontrolloiert eingaben
    if (this.lohnInputkontrolle()) {
      this.lohn.lohnerror = this.lohnInputkontrolle();
      this.lohn.showLohnaenderungen = false;
      return;
    } else {
      this.lohn.lohnerror = false;
      document.getElementById('closelohn').click();
    }

    const end = AghService.sD(this.fi.pdate);
    const geb = AghService.sD(this.fi.gd);
    for (let i = 0; i < this.lohn.datumlohnaenderung.length; i++) {
      // tslint:disable-next-line:triple-equals
      if (typeof this.lohn.lohnsession[i] !== 'undefined') {
        if (this.lohn.lohnsession[i] !== 0) {
          this.pensagh -= this.lohn.lohnsession[i];
        }
      }
      this.lohn.lohnsession[i] = 0;

      if (this.lohn.datumlohnaenderung[i] != null) {
        this.lohn.datumlohnaenderungbe[i] = AghService.sD(this.lohn.datumlohnaenderung[i]);
        this.lohn.hoehelohnaenderungausgabe[i] =  this.lohn.neuermassgebenderlohn[i] - this.lohn.lohnspeicher[i];
        const lohnneu = AghService.calcVL(this.lohn.neuermassgebenderlohn[i]);
        const lohnalt = AghService.calcVL(this.lohn.lohnspeicher[i]);
        this.lohn.hoehelohnaenderung[i] = lohnneu - lohnalt;
        this.lohn.lohnspeicher[i + 1] = this.lohn.neuermassgebenderlohn[i];
      }

      this.lohn.lohnsession[i] += AghService.aghcalc(0, this.lohn.hoehelohnaenderung[i], end, this.lohn.datumlohnaenderungbe[i], this.fi.zins, geb, this.fi.sparplan);
      this.pensagh += this.lohn.lohnsession[i];

    }
    // Rente Neu berechnen:
    this.rentberechenen();
    this.maxkapitalbezug();
    if (this.einkauf.warningtexteinkauf) {
      this.maxeinkauf();
    }

    // Angaben Anzeigen
    this.lohn.showLohnaenderungen = true;

  }

  einmaleinlage(end, isEinkauf = false) {
    // if it is an Einkauf, future Einlagen payments should not be considered

    const beginningOfMonth = true;
    let einlage = 0;
    if (this.fi.anw > 0) {
      // Einmaleinlage / 48
      if (this.fi.anwG === null) {
        this.fi.anwG = 0;
      }
      const monateinmaleinlage = this.fi.anw / 48;
      monateinmaleinlage.toFixed(0);
      // Schon einbezahlt bzw. Anzahlmonate
      const bezahltemonate = this.fi.anwG / monateinmaleinlage;

      bezahltemonate.toFixed(0);
      const einlagenende = new Date('12 / 31 / 2022');
      // Daten
      const geb = AghService.sD(this.fi.gd);
      let start = AghService.sD(this.fi.stg);
      if (beginningOfMonth) { // Annahme dass wenn 1. des Monats, ist erste Rate bereits bezahlt
        start = new Date(start.getFullYear(), start.getMonth(), 1);
      } else {
        start = new Date(start.getFullYear(), start.getMonth() + 1, 0);
      }

      let monate = AghService.monatezwischendaten(start, einlagenende) + 1;
      if (end < einlagenende) {
        monate = AghService.monatezwischendaten(start, end) + 1;
      }
      if (isEinkauf && !beginningOfMonth) {
        monate -= 1;
        if (monate < 0) {
          monate = 0;
        }
      }


      let sumPayments = this.fi.anwG;
      let payments = 0;
      while (true) {
        if (bezahltemonate + payments >= 48) {
          break;
        }
        if (start < AghService.sD(this.fi.stg)) {
          start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
          if (end < start) {
            break;
          }
          continue;
        }
        sumPayments += monateinmaleinlage;
        payments += 1;
        einlage += AghService.aghcalc(monateinmaleinlage, 0, end, start, this.fi.zins, geb, this.fi.sparplan);
        if (beginningOfMonth) {
          start = new Date(start.getFullYear(), start.getMonth() + 1, 1);
        } else {
          start = new Date(start.getFullYear(), start.getMonth() + 2, 0);
        }
        if (end < start) {
          break;
        }
      }
      // Falls nicht alles verzinst wird:
      if (end < einlagenende) {
        // tslint:disable-next-line:max-line-length
        this.kap.einmaleinlagenichtbezahlt = (48 - payments - bezahltemonate) * monateinmaleinlage;
        // Hinzufügen von zukünftigen Auszahlungen von Einlagen, zur Zeit nicht relevant
        if (!isEinkauf) {
          einlage += (48 - payments - bezahltemonate) * monateinmaleinlage;
        }

      }
    }

    return einlage;
  }

  kapitalbezug() {
    if (this.session === 0) {
      this.hilfpensaghkapspeiercher = this.pensagh;
    }
    if (this.kap.showKapitalbezug) {
      this.pensagh = this.pensagh + this.kap.hoehekapitalbezugspeicher + this.kap.zwischenstrafe;

    }
    if (this.kap.maxkapitalbezugbetrag + 2 < this.kap.hoehekapitalbezug) {
      return;
    } else {
      const einlagenende = new Date('11 / 1 / 2022'); // bei der sgpk gab es uws senkung --> einlagen sind die kompensationsmassnahmen. diese laufen bis ende 2022 
      const end = AghService.sD(this.fi.pdate);
      let hilfspensagh = this.pensagh; // + this.kap.hoehekapitalbezug + this.kap.zwischenstrafe;
      if (end < einlagenende) {
        // Höhe einlage
        const monateinmaleinlage = this.fi.anw / 48;
        const monate = AghService.monatezwischendaten(end, einlagenende) + 1;
        const bestrafungsquote = (this.kap.hoehekapitalbezug / (hilfspensagh - (monate * monateinmaleinlage)));
        this.kap.zwischenstrafe = (monate * monateinmaleinlage) * bestrafungsquote;
        hilfspensagh = hilfspensagh - (monate * monateinmaleinlage) * bestrafungsquote;
        this.pensagh = hilfspensagh - this.kap.hoehekapitalbezug;

      } else {
        this.pensagh = this.pensagh - this.kap.hoehekapitalbezug;
      }
      this.kap.hoehekapitalbezugspeicher = this.kap.hoehekapitalbezug;
      this.rentberechenen();
      this.kap.showKapitalbezug = true;
    }
    this.session = 1;
  }

  maxkapitalbezug() {
    let hilfspensagh: number;
    hilfspensagh = this.pensagh + this.kap.hoehekapitalbezug + this.kap.zwischenstrafe;

    const einlagenende = new Date('11 / 1 / 2022');
    const end = AghService.sD(this.fi.pdate);
    this.einmaleinlage(end);

    if (end < einlagenende) {
      // Höhe einlage
      hilfspensagh = hilfspensagh - this.kap.einmaleinlagenichtbezahlt;
      this.kap.maxkapitalbezugbetrag = hilfspensagh * this.maxKapitalbezugProzent;
      this.kap.maxkapitalbezugprozent = this.kap.maxkapitalbezugbetrag / (hilfspensagh + this.kap.einmaleinlagenichtbezahlt) * 100;
    } else {
      hilfspensagh = hilfspensagh + this.kap.einmaleinlagenichtbezahlt;
      this.kap.maxkapitalbezugbetrag = hilfspensagh * this.maxKapitalbezugProzent;
      this.kap.maxkapitalbezugprozent = this.kap.maxkapitalbezugbetrag / hilfspensagh * 100;
    }
  }

  kapitalbezuganzeigeprozent() {
    if (this.session === 0) {
      this.kap.kapitalbezugprozentual = this.kap.hoehekapitalbezug / this.pensagh * 100;
    } else {
      this.kap.kapitalbezugprozentual = this.kap.hoehekapitalbezug / (this.pensagh + this.kap.hoehekapitalbezugspeicher + this.kap.zwischenstrafe) * 100;
    }
  }

  kapitalbezuganzeigebetrag() {
    this.kap.hoehekapitalbezug = this.kap.maxkapitalbezugbetrag / this.kap.maxkapitalbezugprozent * this.kap.hoehekapitalbezugproz;
    if (this.kap.hoehekapitalbezugproz.toFixed(2) === this.kap.maxkapitalbezugprozent.toFixed(2)) {
      this.kap.hoehekapitalbezug = this.kap.maxkapitalbezugbetrag;
    }
  }

  zuruecksetzen() {
    // Werte
    this.einkauf = new Einkauf();
    this.lohn = new Lohn();
    this.kap = new Kapital();

    // Anezige
    this.design.showMyContainer = false;
  }


  rentberechenen() {
    this.rente = AghService.getRente(this.pensagh, this.uws / 100);
    this.monrente = this.rente / 12;
  }

  szenarienzueruecksetzen() {
    // Werte
    this.einkauf = new Einkauf();
    this.lohn = new Lohn();
    this.kap = new Kapital();

    // Rentenrelevantes AGH:
    this.pensagh = 0;
    this.uws = 0;
    this.rente = 0;
    this.monrente = 0;

    this.erstesAGH();
  }

  print() {
    window.print();
  }

  deletelohn(lohnanzeige) {
    for (let i = 0; i < this.lohn.lohnreihe.length; i++) {
      if (this.lohn.lohnreihe[i] === lohnanzeige) {
        this.lohn.lohnreihe.splice(i, 1);
        this.lohn.neuermassgebenderlohn.splice(i, 1);
        this.lohn.datumlohnaenderung.splice(i, 1);
        this.pensagh -= this.lohn.lohnsession[i];
        this.lohn.lohnsession.splice(i, 1);
      }
    }
  }

  addlohn() {
    const i = this.lohn.lohnreihe.length - 1;
    if (this.lohn.neuermassgebenderlohn[i] != null) {
      this.lohn.lohnspeicher[i + 1] = this.lohn.neuermassgebenderlohn[i];
      this.lohn.lohnreihe.push(this.lohn.lohnreihe[this.lohn.lohnreihe.length - 1] + 1);
      this.lohn.warnungzweiterlohnn = false;
    } else {
      this.lohn.warnungzweiterlohnn = true;
    }
  }

  maxeinkauf() {
    // Kontrolle Datum einkauf

    // Sortieren der Szenarien:
    AghService.sortSzenarios(this.einkauf.einkaufsdatum, this.einkauf.einkaufshoehe);

    const geb = AghService.sD(this.fi.gd);
    const st = AghService.sD(this.fi.stg);
    let vl = AghService.calcVL(this.fi.mLohn);


    // Altersguthaben bis Einkauf:
    let agh = 0;

    for (let i = 0; i < this.einkauf.einkaufsdatum.length; i++) {
      // Setzt neues Datum
      if (this.checkdate(this.einkauf.einkaufsdatum[i])) {
        const end = AghService.sD(this.einkauf.einkaufsdatum[i]);

        if (st.getTime() > end.getTime()) {
          this.einkauf.einkauferror = true;
          return;
        } else {
          this.einkauf.einkauferror = false;
        }
        // Check Lohn:
        if (this.lohn.datumlohnaenderung[0] != null) {
          for (let s = 0; s < this.lohn.datumlohnaenderung.length; s++) {
            if (AghService.sD(this.lohn.datumlohnaenderung[s]) < AghService.sD(this.einkauf.einkaufsdatum[i])) {
              agh += AghService.aghcalc(0, this.lohn.hoehelohnaenderung[s], end, AghService.sD(this.lohn.datumlohnaenderung[s]), this.fi.zins, geb, this.fi.sparplan);
              vl = AghService.calcVL(this.lohn.neuermassgebenderlohn[s]);
            }
          }
        }
        // AGH normal bis einkauf
        agh = AghService.aghcalc(this.fi.aghStich, vl, end, st, this.fi.zins, geb, this.fi.sparplan);
        // AGH Einmmal einlage bis zum 1. Datum

        agh += this.einmaleinlage(end, true);

        if (this.einkauf.einkaufzwischen[i] !== 0) {
          agh += this.einkauf.einkaufzwischen[i];
        }

        // Calc Maxeinkauf;
        const einkaufswert = AghService.maxeinkaufberechnen((end.getFullYear() - geb.getFullYear()), end);
        this.einkauf.maxeinkauf[i] = Math.trunc((einkaufswert * vl) - agh);
        if (this.checkdate(this.einkauf.einkaufsdatum[i + 1])) {
          const end1 = AghService.sD(this.einkauf.einkaufsdatum[i + 1]);

          this.einkauf.einkaufzwischen[i + 1] = AghService.aghcalc(this.einkauf.einkaufshoehe[i], vl, end1, end, this.fi.zins, geb, this.fi.sparplan);
        } else {
          this.einkauf.einkaufzwischen[i + 1] = null;
        }
      }
    }
    // Falls die Einkäufe so möglich sind:
    for (let s = 0; s < this.einkauf.einkaufsdatum.length; s++) {
      if (this.einkauf.einkaufshoehe[s] > this.einkauf.maxeinkauf[s] && this.einkauf.einkaufshoehe[s] !== 0) {
        this.einkauf.einkauferror = true;
        return false;
      }
    }
    return true;


  }

}


