import {Injectable} from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AghService {
  // tslint:disable-next-line:max-line-length
  public static standard = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.1635, 0.1645, 0.1655, 0.1675, 0.1695, 0.1715, 0.1725, 0.1735, 0.1755, 0.1775, 0.1795, 0.1815, 0.1835, 0.1855, 0.1875, 0.1895, 0.1915, 0.1935, 0.1955, 0.1975, 0.2015, 0.2035, 0.2055, 0.2075, 0.2095, 0.2135, 0.2175, 0.2195, 0.2215, 0.2255, 0.2295, 0.2335, 0.2375, 0.2415, 0.2455, 0.2495, 0.2495, 0.2495, 0.2495, 0.2495, 0.2495, 0.08, 0.08, 0.08, 0.08, 0.08, 0.08];
  // tslint:disable-next-line:max-line-length
  public static plus = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.18316, 0.18422, 0.18538, 0.1876, 0.18982, 0.19204, 0.1932, 0.19436, 0.19658, 0.1988, 0.20102, 0.20324, 0.20556, 0.20778, 0.21, 0.21222, 0.21444, 0.21676, 0.21898, 0.2212, 0.22564, 0.22796, 0.23018, 0.2324, 0.23462, 0.23916, 0.2436, 0.24582, 0.24804, 0.25258, 0.25702, 0.26156, 0.266, 0.27044, 0.27498, 0.27942, 0.27942, 0.27942, 0.27942, 0.27942, 0.27942, 0.08960, 0.08960, 0.08960, 0.08960, 0.08960, 0.08960];
  // tslint:disable-next-line:max-line-length
  public static minus = [0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.00, 0.14386, 0.14472, 0.14568, 0.1474, 0.14912, 0.15094, 0.1518, 0.15266, 0.15448, 0.1562, 0.15792, 0.15974, 0.16146, 0.16328, 0.165, 0.16672, 0.16854, 0.17026, 0.17208, 0.1738, 0.17734, 0.17906, 0.18088, 0.1826, 0.18432, 0.18786, 0.1914, 0.19312, 0.19494, 0.19848, 0.20192, 0.20546, 0.209, 0.21254, 0.21608, 0.21952, 0.21952, 0.21952, 0.21952, 0.21952, 0.21952, 0.0704, 0.0704, 0.0704, 0.0704, 0.0704, 0.0704];
  public static newEinkauftabelle = [
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.183,
    0.371,
    0.564,
    0.763,
    0.968,
    1.179,
    1.396,
    1.618,
    1.847,
    2.083,
    2.326,
    2.575,
    2.832,
    3.097,
    3.369,
    3.648,
    3.936,
    4.231,
    4.535,
    4.847,
    5.169,
    5.501,
    5.841,
    6.19,
    6.549,
    6.919,
    7.301,
    7.692,
    8.094,
    8.509,
    8.936,
    9.376,
    9.830,
    10.297,
    10.778,
    11.273,
    11.778,
    12.293,
    12.818,
    13.354,
    13.900,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0,
    0.0
  ];


  public static uws2019 = [
    [4.38, 4.39, 4.40, 4.41, 4.41, 4.42, 4.43, 4.44, 4.45, 4.46, 4.46, 4.47],
    [4.48, 4.49, 4.50, 4.51, 4.51, 4.52, 4.53, 4.54, 4.55, 4.56, 4.56, 4.57],
    [4.58, 4.59, 4.60, 4.61, 4.62, 4.63, 4.64, 4.64, 4.65, 4.66, 4.67, 4.68],
    [4.69, 4.70, 4.71, 4.72, 4.73, 4.74, 4.75, 4.76, 4.77, 4.78, 4.79, 4.80],
    [4.81, 4.82, 4.83, 4.84, 4.85, 4.86, 4.87, 4.88, 4.89, 4.90, 4.91, 4.92],
    [4.93, 4.94, 4.95, 4.96, 4.97, 4.98, 5.00, 5.01, 5.02, 5.03, 5.04, 5.05],
    [5.06, 5.07, 5.08, 5.10, 5.11, 5.12, 5.13, 5.14, 5.15, 5.17, 5.18, 5.19],
    [5.20, 5.21, 5.23, 5.24, 5.25, 5.26, 5.28, 5.29, 5.30, 5.31, 5.33, 5.34],
    [5.35, 5.36, 5.38, 5.39, 5.40, 5.42, 5.43, 5.44, 5.46, 5.47, 5.48, 5.50],
    [5.51, 5.52, 5.54, 5.55, 5.57, 5.58, 5.60, 5.61, 5.62, 5.64, 5.65, 5.67],
    [5.68, 5.70, 5.71, 5.73, 5.74, 5.76, 5.78, 5.79, 5.81, 5.82, 5.84, 5.85],
    [5.87, 5.89, 5.90, 5.92, 5.94, 5.97, 5.95, 5.99, 6.00, 6.02, 6.02, 6.05],
    [6.07, 6.07, 6.07, 6.07, 6.07, 6.07, 6.07, 6.07, 6.07, 6.07, 6.07, 6.07]
  ];

  /* AGH - äuffnen */
  static aghcalc(agh: number, vl: number, end: any, st: any, iagh: number, geb: any, plan: string) {

    let ags = null;

    // Planzuweisung
    if (plan === 'Plus') {
      ags = this.plus;
    } else if (plan === 'Minus') {
      ags = this.minus;
    } else {
      ags = this.standard;
    }
    iagh = +iagh;
    let log = 0;

    if (st === end || end < st) {
    } else {
      // tslint:disable-next-line:variable-name
      const diff_dd = end.getDate() - st.getDate();
      const diffjahre = end.getFullYear() - st.getFullYear();
      let bvgalter = st.getFullYear() - geb.getFullYear();
      // console.log('BVGalter:   ' + bvgalter);
      if (diff_dd < 0 && diffjahre < 0) {
        return agh;
      } else {
        if (st.getMonth() === 0 && st.getDate() === 1) {
          log = 1;
        } else {
          log = 0;
        }
        const eta = (Math.max(30 - st.getDate(), 0) + log + (11 - (st.getMonth())) * 30) / 360;
        const gamma = (Math.min(end.getDate(), 30) + ((end.getMonth()) * 30)) / 360;
        // console.log('eta   '+ eta +'   '+  gamma + '   '+ diffjahre );
        if (diffjahre === 0) {
          agh = agh * (1 + iagh * (gamma - (1 - eta))) + vl * ags[bvgalter] * (gamma - (1 - eta));

        } else {
          agh = agh * (1 + iagh * eta) + vl * ags[bvgalter] * eta;

          bvgalter = bvgalter + 1;
          if (diffjahre === 1) {
            agh = agh * (1 + iagh * gamma) + vl * ags[bvgalter] * gamma;
          } else {
            for (let i = 1; i < diffjahre; i++) {
              agh = (agh * (1 + iagh)) + (vl * ags[bvgalter]);
              bvgalter = bvgalter + 1;

            }
            agh = agh * (1 + iagh * gamma) + (vl * ags[bvgalter] * gamma);

          }
        }
        return agh;
      }
    }
  }

  /* VL - aus masgebenden Lohn Rechnen */
  static calcVL(mLohn) {
    const lohn = mLohn;
    const koord_abz_relative = 0.2;
    const koord_abz_max = 14340;
    // tslint:disable-next-line:variable-name
    const vl_min = 11472;
    const vl_max = 329820;
    let vl = null;
    if (lohn * koord_abz_relative > koord_abz_max && lohn - koord_abz_max <= vl_max) {
      vl = lohn - koord_abz_max;
    } else {
      vl = lohn - (lohn * koord_abz_relative);
      if (vl < vl_min) {
        vl = 0;
      }
      if (lohn - koord_abz_max > vl_max) {
        vl = vl_max;
      }
    }

    console.log("vers Lohn ungerundet ", vl);
    vl = Math.round(vl);
    console.log("vers Lohn ", vl);
    return vl;
  }

  /* uws waehlen*/
  static returnUWS(geb, end) {
    // tslint:disable-next-line:no-shadowed-variable
    function alterpens(end: any, geb: any) {
      if (geb.getMonth() <= end.getMonth()) {
        return end.getFullYear() - geb.getFullYear();
      } else {
        return end.getFullYear() - geb.getFullYear() - 1;

      }
    }

    // tslint:disable-next-line:no-shadowed-variable
    function monatpens(end, geb) {
      if (geb.getMonth() <= end.getMonth()) {
        return (end.getMonth() - geb.getMonth());
      } else {
        return end.getMonth() + 12 - geb.getMonth();
      }
    }

    return this.uws2019[alterpens(end, geb) - 58][monatpens(end, geb)] / 100;
  }

  /* Rente Berechnen Jahr*/
  static getRente(pensagh, uws) {
    return pensagh * uws;
  }

  /* Sortiert Einkaufsdatum*/
  static sortSzenarios(einkaufsdatum, einkaufshoehe) {

    let swapped;
    do {
      swapped = false;
      for (let i = 0; i < einkaufsdatum.length - 1; i++) {
        if (einkaufsdatum[i] > einkaufsdatum[i + 1]) {

          const temp = einkaufsdatum[i];

          einkaufsdatum[i] = einkaufsdatum[i + 1];
          einkaufsdatum[i + 1] = temp;


          const temp1 = einkaufshoehe[i];
          einkaufshoehe[i] = einkaufshoehe[i + 1];
          einkaufshoehe[i + 1] = temp1;
          swapped = true;
        }
      }
    } while (swapped);



  }

  /* Berechnet anzahl monate zwischen den daten*/
  static monatezwischendaten(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  }

  static sD(dateString) {
    const neuesDatum = new Date(dateString.replace(/(\d{1,2}).(\d{1,2}).(\d{2,4})/, '$2/$1/$3'));
    if (neuesDatum.getFullYear() < 1940) {
      neuesDatum.setFullYear(neuesDatum.getFullYear() + 100);
    }
    return neuesDatum;
  }

  static maxeinkaufberechnen(alter, endDate) {
    const daysOfMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
    const factor1 = (12 - endDate.getMonth() - 1 + (daysOfMonth - endDate.getDate()) / daysOfMonth);
    const factor2 = (endDate.getMonth() + endDate.getDate() / daysOfMonth);
    const value = (this.newEinkauftabelle[alter - 1] * factor1 + this.newEinkauftabelle[alter] * factor2) / 12.0;

    return value;
  }
}



