import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {FormsModule} from '@angular/forms';
import {registerLocaleData} from '@angular/common';
import localedeCH from '@angular/common/locales/de-CH';

registerLocaleData(localedeCH);
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {CurrencyMaskConfig, CURRENCY_MASK_CONFIG} from 'ng2-currency-mask/src/currency-mask.config';
import {AngularFontAwesomeModule} from 'angular-font-awesome';


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  decimal: '.',
  precision: 0,
  prefix: '',
  suffix: '',
  thousands: '’'
};


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    CurrencyMaskModule,
    AngularFontAwesomeModule,
    FormsModule
  ],
  providers: [{provide: LOCALE_ID, useValue: 'de-CH'},
    {provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
